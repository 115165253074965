<template>
    <div></div>
</template>

<script>
export default {
    created() {
        this.$toast({ type: 'loading', message: '登录中...', duration: 0 });
        let code = this.$route.query.code;
        let path = this.$route.query.path ? decodeURIComponent(this.$route.query.path) : '/index';
        let state = this.$route.query.state;
        if (code && state) {
            if (state == 'snsapi_base') {
                this.$api('login', { code }).then((res) => {
                    this.$store.commit('login', { user_id: res.user_id, token: res.token });
                    this.$toast({ type: 'success', message: '登录成功', duration: 100 });
                    this.$router.replace(path);
                });
            } else if (state == 'snsapi_userinfo') {
                this.$api('login', { code }).then((res) => {
                    this.$store.commit('login', { user_id: res.user_id, token: res.token });
                    this.$toast({ type: 'success', message: '登录成功', duration: 100 });
                    this.$router.replace(path);
                });
            } else {
                this.$router.replace('/auth');
            }
        } else {
            this.$router.replace('/auth');
        }
    },
};
</script>

<style></style>
